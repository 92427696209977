import React, { useState } from 'react';
import { encode } from "base-64";

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [deviceToken, setDeviceToken] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [token, setToken] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDeviceId("1");
    setDeviceToken("1");

    
    try {

      //const authorization = 'Basic '+ btoa("vetmate:vetmate@123");  

      const uname = 'vetmate';
      const pass = 'vetmate@123';

      const authorization = 'Basic ' + encode(uname + ":" + pass);  

      console.log("Auth:" + authorization);

      const response = await fetch('https://api.vetmate.io/api/v1/user/login', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Authorization': 'authorization',  
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'api_key': '1234',
            'platform': 'ANDROID',
            'timezone': '0' ,
            'language': 'en',

        },
        body: JSON.stringify({
          email,
          password,
          deviceId,
          deviceToken
        }),
      });
      const resp = await response;
      console.log(resp);
      const data = resp.json()
      if (data.token) {
       console.log(data);
       setToken(data.token);
      }
    } catch (error) {
      console.log(error);
      //console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">Email:</label>
      <input
        type="text"
        id="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <br />
      <label htmlFor="password">Password:</label>
      <input
        type="password"
        id="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
      <br />
      <button type="submit">Login</button>
      {token ? <p>Logged in! Token: {token}</p> : null}
    </form>
  );
}

export default LoginForm;